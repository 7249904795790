<template>
    <article
        v-if="entry"
        id="main"
        role="main"
        tabindex="-1"
    >
        <TheNavigation
            v-if="!menuHidden"
            class="the-navigation--light the-navigation--home"
            :call-to-action="callToAction"
        />
        <HeroLogo v-else />

        <HeroHome
            :title="pageTitle"
            :sub-title="pageSubTitle"
            :image="image"
            :image-mobile="imageMobile"
            :show-video="showVideo"
            :video="video"
            :video-mobile="videoMobile"
        />

        <ClientOnly>
            <UnibuddyBubble v-if="showUnibuddy" />
        </ClientOnly>

        <FlexibleSections :sections="entry?.flexibleSection ?? []" />
    </article>
</template>

<script setup>
import HomeQuery from '~/graphql/queries/getHome.graphql';

const { $i18n } = useNuxtApp();
const siteHandle = $i18n?.localeProperties?.value?.craftSiteHandle;
const variables = computed(() => {
    return {
        siteHandle
    };
});

const response = await useAsyncQuery({
    query: HomeQuery,
    variables
});

const { craftEntry: entry } = useCraftPageEther(response);

const callToAction = computed(() => entry.value?.callToAction?.[0] ?? null);
const showUnibuddy = computed(() => entry.value?.showUnibuddy ?? false);
const pageTitle = computed(() => entry.value?.header ?? entry.value?.title ?? '');
const pageSubTitle = computed(() => entry.value?.subTitle ?? '');
const image = computed(() => entry.value?.image?.[0] ?? null);
const imageMobile = computed(() => entry.value?.imageMobile?.[0] ?? null);
const showVideo = computed(() => entry.value?.showVideo ?? false);
const video = computed(() => entry.value?.video ?? null);
const videoMobile = computed(() => entry.value?.videoMobile ?? null);
const menuHidden = computed(() => entry.value?.hideMenu ?? false);
</script>
